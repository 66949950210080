@import 'theme';

.m-sub-title {
    color: $blue-3;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.modal-content {
    border-radius: 10px;
}

.info-content {
    color: $grey-3;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.card {
    form 
    {
        .form-group {
            .showandhidepass {
                position: absolute;
                top: 50%;
                right: 20px;
                font-size: 2.5rem;
                color: $grey-2;
                transform: translateY(-50%);
            }
        }
        input#phone {
            opacity: 1 !important;
            border: 1px solid $grey-1;
            border-radius: 10px;
            background: transparent;
            border-radius: 10px;
            padding: 20px 20px 20px 65px;
            height: 50px;
            font-size: 14px;
            font-weight: 500;
            &::placeholder {
                color: $blue-3 !important;
                font-size: 1.3rem;
                opacity: 0.4 !important;
            }
            &:focus {
                box-shadow: none !important;
            }
        }
        .has-input + input#phone {
            padding: 20px 20px 9px 20px !important;
        }
        .term-content {
            margin-top: 2rem;
            font-size: 10px;
            color: $grey-3;
            font-weight: 400;
            
            font-style: normal;
            line-height: normal;
            a {
                color: $grey-3;
            }
        }

        .continue-with-box {
            .social-button {
                background-color: $blue-2;
                width: 100%;
                height: 51px;
                background-color: transparent;
                border: 1px solid $grey-1;
                border-radius: 10px !important;
                padding: 0.5rem 1rem;
                .social-display {
                    display: flex !important;
                    flex-flow: row nowrap;
                    float: left;
                    line-height: 20px;
                    .social-img {
                        height: 2.5rem;
                        width: 2.5rem;
                        margin-left: 0.8rem;
                        margin-top: -0.2rem;
                    }
                    .social-login {
                        padding-top: 0rem;
                        width: 94%;
                        margin-left: 0rem;
                        color: $blue-3;
                        position: absolute;
                        font-size: 3.8vw;
                        font-weight: 500;
                    }
                    svg {
                        margin: 0 0 0 7px;
                    }
                }
            }
        }
    }
}
.popup-container.verify-otp {
    align-items: normal;
    background: none;
}
.confirm-phone {
    .term-content {
        color: $grey-3;
        
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 15px;
        display: flex;
    }
    select {
        border-radius: 10px 10px 0px 0;
        background: transparent;
        border: 1px solid $grey-1;
        height: 50px;
        padding: 20px 20px 20px 20px;
        font-size: 14px;
        font-weight: 500;
    }

    select {
        border-radius: 10px 10px 0px 0;
        background: transparent;
        border: 1px solid $grey-1;
        height: 50px;
        padding: 20px 20px 20px 20px;
        font-size: 14px;
        font-weight: 500;
    }

    .form-group {
        position: relative;
    }
    label {
        font-size: 12px;
        position: absolute;
        top: 8px;
        left: 20px;
        line-height: normal;
        position: absolute;
        opacity: 0;
        transition: opacity 0.15s ease-in, top 0.2s linear;
    }
    .iti__flag {
        display: none;
    }
    .iti.separate-dial-code input#phone {
        opacity: 1 !important;
        border: 1px solid $grey-1;
        border-radius: 0 0 10px 10px !important;
        background: transparent;
        border-radius: 10px;
        padding: 20px 20px 3px 65px;
        height: 50px;
        font-size: 14px;
        font-weight: 500;
    }

    .iti__selected-flag.dropdown-toggle {
        top: 8px;
    }

    label.has-input {
        opacity: 1;
    }
    .has-input + input {
        padding: 20px 20px 8px 20px;
    }

    .form-group .md-input-error .iti.separate-dial-code input#phone {
        box-shadow: 0 0 1.5px 2px $red-1 inset !important;
    }

    .iti--allow-dropdown .iti__flag-container, .iti--separate-dial-code .iti__flag-container {
        pointer-events: none;
    }
    .iti__arrow {
        display: none;
    }
    
}
.modal-dialog.modal-md.verify-otp {
    .modal-content {
      margin-top: $base-spacing * 2.5;
      box-shadow: 0 $base-spacing $base-spacing * 2 0 rgb(0 0 0 / 20%),
      0 $base-spacing * 1.5 $base-spacing * 5 0 rgb(0 0 0 / 19%);
      padding: 0;
      min-height: 10rem;
      border-radius: 15px;

      .modal-header {

        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 16px 30px;
        border-bottom: 1px solid $grey-1;

        .popup-close-btn {
          position: absolute;
          right: 0;
          top: 0;
          font-size: $font-size-xxxl;
          font-weight: $font-weight-semibold;
          line-height: $base-spacing * 8.75;
          padding: $base-spacing * 1.25 $base-spacing * 2.5;
          height: $base-spacing * 1.125;
        }

        img {
          width: 30vw;
          height: 30vw;
        }

        h4 {
          width: fit-content;
          text-align: center;
          font-size: $font-size-xl;
          font-weight: $font-weight-bold;
          margin-top: $base-spacing * 1.25;
        }
      }

      input#login-otp-input {
        background: none;
      }

      .modal-body {
        padding: $base-spacing * 2.5 $base-spacing * 2.5 $base-spacing * 1.25;
        font-size: $font-size-xxs;
        font-weight: $font-weight-normal;
        color: $grey-3;

        p, .text-receive-otp {
          text-align: center;
          font-size: $font-size-s;
          font-weight: $font-weight-normal;
          color: $blue-2;
          margin-bottom: $base-spacing * 3.75;
          color: $grey-3;
          
          font-style: normal;
          line-height: normal;
        }
        .text-receive-otp {
          margin-top: 15px;
        }

        form {
          margin-top: 0;
          .form-group {
            display: flex;
            justify-content: center;
            margin-bottom: 1rem;

            #login-otp-input {
              background-image: linear-gradient(
                              to left,
                              $blue-0 $base-spacing * 10,
                              rgba(255, 255, 255, 0) 0
              );
              background-repeat: repeat;
              color: $blue-3;
              font-size: $font-size-xxl;
              font-weight: $font-weight-semibold;
              letter-spacing: $base-spacing * 5;
              width: $base-spacing * 50;
              background-position: $base-spacing * 10 center;
              background-size: $base-spacing * 12 1px;
              border-radius: 0;
              padding-left: 1.6rem;
              border: none;
              outline: none;

              &:focus-visible {
                outline: none;
              }
            }

            div {
              font-size: 1.55rem;
            }

            a {
              color: $grey-3;
              font-size: $font-size-s;
              padding: 0 $base-spacing * 1.5;
              text-decoration: underline;
            }

            button {
              color: $white;
              border: none;
              padding: $base-spacing * 1.5 $base-spacing * 5;
              font-weight: bold;
              width: auto;
              border-radius: 8px;
              background: $blue-2;
              font-size: $font-size-xs;
            }
          }
        }
      }
    }
}

.verify-otp {
    button {
        &:disabled{
            background-color: $grey-1 !important;
        }
    }
}

.custom-offcanvas {
    height: 96% !important;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    display: flex;
    .modal-header {
        justify-content: space-between;
        position: relative;
        padding: 16px 30px;
        border-bottom: 1px solid $grey-1;
    }
}

#partitioned {
    display: flex;
    justify-content: center;
    @media (max-width: $mobile-width) {
        justify-content: flex-start;
    }
}

.otp-input {
    color: $grey-3;
    
    font-size: 12px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 30px !important;
}

.ng-otp-input-wrapper.wrapper.ng-star-inserted {
    border: 1px solid $grey-1;
    padding: 2px;
    border-radius: 10px;

    input {
        border: none !important;
        background: $white;
    }
}

.login-text {
    color: $blue-3; 
    font-size: 16px; 
     
    font-weight: 600; 
    word-wrap: break-word;
} 
.m-content.blue {
  color: $blue-2;
  margin-top: 15px;
}
.new-ui {
    form {
        .content-body {
          display: flex;
          justify-content: space-between;
          margin: 15px 0;
          .term-content {
            color: $grey-3;
            
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            width: 70%;
            margin: 0;
          }
        
          .create-account  {
            width: 30%;
            text-align: right;
            margin: 0;
            color: $grey-3;
            
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        
          a {
            color: $grey-3;
          }
        }
    }

    .forget-pass-new-acc {
        color: $grey-3;
        
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-decoration-line: underline;
        a {
            color: $grey-3;
        }
    }
}

.card{
  width: 100%;
  background: $white;
}

.new-ui form .form-group label {
    color: $grey-3;
    font-size: $font-size-xs;
    position: absolute;
    top: 8px;
    left: 20px;
    line-height: normal;
}

.new-ui form .form-group {
    input {
      border-radius: 10px;
      background: transparent;
      border: 1px solid $grey-1;
      height: 50px;
      padding: 20px 20px 20px 20px;
      font-size: 14px;
      font-weight: 500;
    }
    
    .has-input + input {
        padding: 20px 20px 8px 20px;
    }
}
.new-ui form .form-group.has-input input {
    padding: 20px 20px 8px 20px;
}

.new-ui form .form-group.has-input input#phone {
    padding: 20px 20px 3px 65px !important;
}

.new-ui form .form-group.has-input .iti__selected-flag {
    top: 8px;
}

.new-ui .iti__flag {
    display: none;
}

.m-text-center {
    text-align: center;
}

.m-text-underline {
    text-decoration: underline;
}

.m-p-80 {
    padding: 0 55px;
}

.m-content {
    color: $grey-3;
    
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.verify-otp-mobile {
    .m-content {
        color: $grey-4;
        
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 25px;
    }
}

.m-button {
    width: 100%;
    font-size: $font-size-m;
    height: $base-spacing * 11.25;
    border-radius: 10px !important;
    
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    &:disabled{
        background-color: $grey-1;
    }
}
.m-button-white {
    color: $white;
    
}

.m-button-grey {
    color: $grey-3;
    border-color: $grey-1 !important;
    &:hover{
        color: $white !important;
        background-color: $grey-1 !important;
    }
    &:focus{
        color: $white !important;
        background-color: $grey-1 !important;
    }
    &:active{
        color: $white !important;
        background-color: $grey-1 !important;
    }
}

.m-bottom-20 {
    margin-bottom: 20px;
}
.m-bg-blue {
    background: $blue-2;
}

.m-bg-transparent {
    background: transparent;
    &:hover{
        background-color: transparent;
        color: $grey-3;
        border: 1px $grey-1;
    }
}

button.calendar,
button.calendar:active {
    width: 2.75rem;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAcCAYAAAAEN20fAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAOxAAADsQBlSsOGwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAEUSURBVEiJ7ZQxToVAEIY/YCHGxN6XGOIpnpaEsBSeQC9ArZbm9TZ6ADyBNzAhQGGl8Riv4BLAWAgmkpBYkH1b8FWT2WK/zJ8ZJ4qiI6XUI3ANnGKWBnht2/ZBDRK3hgVGNsCd7/ui+JkEIrKtqurLpEWaphd933+IyI3LEIdpCYCiKD6HcuOa/nwOa0ScJEnk0BJg0UTUWJRl6RxCYEzEmomsIlPU3IPW+grIAbquy+q6fluy/28RIBeRMwDXdXMgXLj/B2uimRXpui4D9sBeRLKl+1N+L+t6RwbWrZliTTTr1oxYtzVWiTQAcRxvTX+eJMnlUDaO1vpZRO5NS0x48sIwfPc87xg4B04MCzQi8hIEwe4bl1DnFMCN2zsAAAAASUVORK5CYII=") !important;
    background-repeat: no-repeat;
    background-size: 23px;
    background-position: center;
}

.forgot-password form {
    margin-top: 0;
    @media (max-width: $mobile-width) {
        margin-top: $base-spacing * 7.5;
    }
}

.c-po-modal-content form, .modal-body form {
    margin-top: 0;
    @media (max-width: $mobile-width) {
        margin-top: 3rem;
    }
}

.card {
  form {
    .form-group {
      input {
        &.md-input-error {
          box-shadow: 0 0 1.5px 2px $red-1 inset;
          color: $red-2 !important;
          &:focus {
            box-shadow: 0 0 1.5px 2px $red-1 inset;
            color: $red-2 !important;
          }
        }
      }
    }
  }
}

.register-component-parent-div .card form .form-group .md-input-error .iti.separate-dial-code input#phone,
.main-div .card .card-body form .form-group .md-input-error .iti.separate-dial-code input#phone {
  box-shadow: 0 0 1.5px 2px $red-1 inset!important;
}

.md-error-shake {
  animation: shake 0.2s ease-in-out 0s 2;
}

@keyframes shake {
  0% { margin-left: 0; }
  25% { margin-left: 0.5rem; }
  75% { margin-left: -0.5rem; }
  100% { margin-left: 0; }
}

a {
  cursor: pointer;
}

.error-modal, .info-modal {
  .modal-content {
    .modal-body {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 2 * $base-spacing;
      padding-top: 4 * $base-spacing;
      padding-bottom: 4 * $base-spacing;
      align-items: center;
      justify-content: center;
      font-size: $font-size-s;
      text-align: center;

      .modalbtn {
        margin: 0;
        font-size: $font-size-s;
      }
    }
  }
}

.lgn-modal, .rgtr-modal, .frgt-pass-modal, .pw-modal, .chg-pass-modal, .m-modal {
  min-height: 10rem;

  .modal-content {
    padding: 0;
    min-height: 10rem;
    border-radius: 15px;
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
    position: relative;
    padding: 16px 30px;
    border-bottom: 1px solid $grey-1;

    img {
      max-width: 150px;
    }
  }

  .modal-body{
    padding: 16px 30px;
  }

  .main-div {
    max-height: 650px;
  }
}

.lgn-modal {
  .main-div {
    height: auto;
  }
}

.rgtr-modal {
  .main-div {
    height: auto;
  }
}

.pw-modal {
  .main-div {
    max-height: 650px;
  }
}

@media only screen and (min-width: $mobile-width + 1) {
  .deals-card {
    .swiper-container {
      border-radius: 8px;
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

.border-radius-15 {
  .modal-content {
    border-radius: 15px;
  }
}
.notification-text {
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 4px;
}